import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
import Aheader from "../Aheader/Aheader";
import { Sidebar } from "./Sidebar";

const ManageRecord = () => {
  const [record, setRecord] = useState([]);

 const [userData, setUserData] = useContext(UserContex);
 // console.log(userData?.user);
 const navigate = useNavigate();
 useEffect(() => {
   if (!userData.user) {
     navigate("/");
   }
 }, [userData.user, navigate]);
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/record/getrecord")
      .then((res) => {
        setRecord(res.data.data);
        console.log(record);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className=" ">
      <Aheader />
      <div className="flex">
        <Sidebar />
        <div className="bg-gray-400 h-screen pt-12 ml-60 mt-20 w-full">
          <div className="text-gray-800 flex justify-center mt-2 mb-4">
            <h1 className="uppercase font-bold text-2xl underline">
              Manage Record
            </h1>
          </div>
          <div className="grid grid-cols-1 ">
            <div>
              <div className="px-2 w-full ">
                <table className="border-collapse border border-slate-500 w-full bg-white ">
                  <thead className="font-bold bg-gray-300">
                    <tr>
                      <td className="border border-slate-700 font-serif">#</td>
                      <td className="border border-slate-700 font-serif">
                        Name
                      </td>
                      <td className="border border-slate-700 font-serif">
                        Percent
                      </td>
                      <td className="border border-slate-700 font-serif">
                        Action
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {record.map((data, index) => (
                      <tr>
                        <td className="border border-slate-600">{index}</td>
                        <td className="border border-slate-600">{data.name}</td>
                        <td className="border border-slate-600">
                          {data.percent}
                        </td>
                        <td className="border border-slate-600">
                          <button
                            type=""
                            className=" bg-red-500 my-1 text-white px-4  rounded rounded-fully"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="bg-white  border border-black-1 border-rounded rounded-2xl p-4 h-64 ml-4 mr-2">
              <form className="">
                
                <div className="grid grid-cols-2">
                  <div className=" mr-4 mb-4">
                    <label className="font-bold">Practical</label>
                    <input
                      placeholder="Practical"
                      type="text"
                      // onChange={(e) => setCourse(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                  </div>
                  <div className=" mr-4 mb-4">
                    <label className="font-bold">Theory</label>
                    <input
                      placeholder="Theory"
                      type="text"
                      // onChange={(e) => setCourse(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                  </div>
                  <div className=" mr-4 mb-4">
                    <label className="font-bold">Industry Assesment</label>
                    <input
                      placeholder="Industry Assesment"
                      type="text"
                      // onChange={(e) => setCourse(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                  </div>
                </div>
                <div className="flex justify-center mt-2">
                  <button
                    type=""
                    className="uppercase bg-red-500 mt-4 text-white px-4 py-1 rounded rounded-fully"
                  >
                    Submit
                  </button>
                </div>
              </form>
              
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageRecord;
