import React, { useContext, useEffect, useState } from "react";
import { Sidebar1 } from "../Registration/Sidebar1";
import Aheader from "../Aheader/Aheader";
import "./Registration.css";
import { UserContex } from "../../context API/UserContext";
import { ListItemPrefix } from "@material-tailwind/react";
import { PowerIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
function Registrationemployee({ logout }) {
  // const [studentCount, setStudentCount] = useState(0);
  const [userData, setUserData] = useContext(UserContex);
  // console.log(userData.user?.name);
  const navigate = useNavigate();
   // console.log(userData?.user);
   useEffect(() => {
     if (!userData.user) {
       navigate("/");
     }
   }, [userData.user, navigate]);
  useEffect(() => {
    if (!userData.user) {
      navigate("/");
    }
  }, [userData.user, navigate]);
  return (
    <div>
      <Aheader logout={logout} />
      <div className="flex">
        <Sidebar1 />
        <div className="ml-60 mt-20 bg-white text-black w-full pt-8">
          <div className="mb-40 ml-20 flex justify-end items-center">
            <Link
              to={`/changepassword`}
              className="bg-black border border-gray-300 text-gray-900 text-white rounded-lg block  p-2.5 font-bold  hover:text-black hover:bg-white hover:border-black"
            >
              Change Password
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h2 className="font-bold text-4xl lg:text-6xl uppercase">
              <marquee behavior="" direction="">
                Wellcome To Registrar
              </marquee>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registrationemployee;
