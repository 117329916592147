import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Aheader from "../Aheader/Aheader";
import { Sidebar1 } from "./Sidebar1";
import axios from "axios";
import { UserContex } from "../../context API/UserContext";
const StudentRecord = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  // const [student, setStudent] = useState(null);
  const [userData, setUserData] = useContext(UserContex);
  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");
  const [last, setLast] = useState("");
  const [year, setYear] = useState("");
  const [dep, setDep] = useState("");
  const [departments, setDepartments] = useState([]);
   // console.log(userData?.user);
   useEffect(() => {
     if (!userData.user) {
       navigate("/");
     }
   }, [userData.user, navigate]);
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/dep/getDep")
      .then((res) => {
        setDepartments(res.data.data);
        // console.log(departments);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/student/getStudents")
      .then((res) => {
        setStudents(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.akptc.et/api/student/sStudent", {
        first,
        middle,
        last,
        year,
        dep,
      })
      .then((res) => {
        console.log(res.data.data);
        setStudents(res.data.data);
      })
      .catch((err) => alert(err.response.data.msg));
  };
  console.log(dep);
  let yers = [];
  for (let y = 2008; y <= 2050; y++) {
    yers.push(y);
    console.log(y);
  }
  // const handleClick = async (id) => {
  //   try {
  //     await axios.delete("https://backend.akptc.et/api/student/" + id);
  //     window.location.reload();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // console.log(students.id);
  return (
    <div>
      <Aheader />
      <div className="flex">
        <Sidebar1 />
        <div className="w-full ml-60 mt-20">
          {/* <h3 className="font-bold text-500 ">
            Wellcome to student registration
          </h3> */}
          <div className="flex justify-between my-4 py-2 pr-4">
            <div className="ml-4">
              <form onSubmit={handleSubmit}>
                <label>Search</label>
                <div className="grid grid-cols-3 gap-2 w-3/4">
                  <input
                    type="text"
                    placeholder="first name"
                    onChange={(e) => {
                      setFirst(e.target.value);
                    }}
                    className="ml-2 border border-gray-400"
                  />
                  <input
                    type="text"
                    placeholder="middle name"
                    onChange={(e) => {
                      setMiddle(e.target.value);
                    }}
                    className="ml-2 border border-gray-400"
                  />
                  <input
                    type="text"
                    placeholder="last name"
                    onChange={(e) => {
                      setLast(e.target.value);
                    }}
                    className="ml-2 border border-gray-400"
                  />
                  {/* <input
                    type="text"
                    placeholder="year"
                    onChange={(e) => {
                      setYear(e.target.value);
                    }}
                    className="ml-2 border border-gray-400"
                  /> */}
                  <select
                    id="countries"
                    onChange={(e) => {
                      setYear(e.target.value);
                    }}
                    className="ml-2 border border-gray-400"
                  >
                    <option selected>year</option>
                    {yers.map((data, index) => (
                      <option value={data}>{data}</option>
                    ))}
                  </select>
                  <select
                    id="deplist"
                    onChange={(e) => setDep(e.target.value)}
                    placeholder="Department"
                    className=" h-8 pl-2 border border-gray-400"
                  >
                    <option selected>Occupation</option>
                    {departments.map((data, index) => (
                      <option value={data.id}>{data.dep_name}</option>
                    ))}
                  </select>
                  {/* <input
                    type="text"
                    placeholder="department"
                    onChange={(e) => {
                      setDep(e.target.value);
                    }}
                    className="ml-2 border border-gray-400"
                  /> */}
                  <button type="submit">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-8 h-8 border border-gray-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="font-bold font-serif underline uppercase mb-4  text-500 flex justify-center">
            <h2>Trainee Record</h2>
          </div>
          <div className="px-2 ">
            <table className="border-collapse border border-slate-500 w-full  ">
              <thead className="font-bold bg-gray-300">
                <tr>
                  <td className="border border-slate-700 font-serif">#</td>
                  <td className="border border-slate-700 font-serif">
                    Full Name
                  </td>

                  <td className="border border-slate-700 font-serif">Sex</td>
                  <td className="border border-slate-700 font-serif">Skill</td>
                  <td className="border border-slate-700 font-serif">Level</td>

                  <td className="border border-slate-700 font-serif">Action</td>
                </tr>
              </thead>
              <tbody>
                {students.map((data, index) => (
                  <tr>
                    <td className="border border-slate-600">{index + 1}</td>
                    <td className="border border-slate-600">
                      {data.first_name +
                        " " +
                        data.middle_name +
                        " " +
                        data.last_name}
                    </td>

                    <td className="border border-slate-600">{data.sex}</td>
                    <td className="border border-slate-600">{data.dep_name}</td>
                    <td className="border border-slate-600">{data.level}</td>

                    <td className="border border-slate-600 flex">
                      <Link className="mr-2" to={`/dislpayRecord/${data.id}`}>
                        {/* /dislpayRecord/${data.id} */}
                        <svg
                          xmlns="https://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0 0 30 30"
                        >
                          <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
                        </svg>
                      </Link>
                      <Link
                        to={`/enterrecord/${data.id}`}
                        // onClick={(e) => handleClick(data.id)}
                        className="mr-2 text-white bg-black border rounded rounded-fully my-1 px-2"
                      >
                        Trainee Record
                      </Link>
                      {/* <Link to={`/enterrecord/${data.id}`}>enter record</Link> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StudentRecord;
