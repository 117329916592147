import React, { useContext, useEffect } from "react";
import axios from "axios";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import User from "./Pages/Admin/User";
import UserAdd from "./Pages/Admin/UserAdd";
import Department from "./Pages/Admin/Department";
import Adminhome from "./Pages/Admin/Adminhome";
import Login from "./Pages/Login/Login";
import Registrationemployee from "./Pages/Registration/Registrationemployee";
import Student from "./Pages/Registration/Student";
import DetailStudent from "./Pages/Registration/DetailStudent";
import StudentRegistration from "./Pages/Registration/StudentRegistration";
import Ulaagaa from "./Pages/Admin/Ulaagaa";
import { UserContex } from "./context API/UserContext";
import Courses from "./Pages/Admin/Courses";
import StudentRecord from "./Pages/Registration/StudentRecord";

import DisplayRecord from "./Pages/Registration/DisplayRecord";
import ManageRecord from "./Pages/Admin/ManageRecord";
import EnterRecord from "./Pages/Registration/EnterRecord";
import ChangePassword from "./Pages/Admin/ChangePassword";

function App() {
  const [userData, setUserData] = useContext(UserContex);
  const checkLoggedin = () => {
    let token = localStorage.getItem("auth-token");
    if (token === null) {
      localStorage.setItem("auth-token", "");
      token = "";
    } else {
      axios
        .get("https://backend.akptc.et/api/user", {
          headers: { "x-auth-token": token },
        })
        .then((res) =>
          setUserData({
            token,
            user: {
              name: res.data.data.user_name,
              uid: res.data.data.user_id,
              role: res.data.data.user_role,
            },
          })
        )
        .catch((err) => console.log("error happened"));
    }
  };
  console.log(userData.user);

  useEffect(() => {
    checkLoggedin();
  }, []);
  const logout = () => {
    setUserData({
      token: undefined,
      user: undefined,
    });
    localStorage.setItem("auth-token", "");
  };
  return (
    <div>
      <RouterProvider
        router={createBrowserRouter([
          {
            path: "/admin",
            element: <Adminhome logout={logout} />,
          },
          {
            path: "/registration",
            element: <Registrationemployee logout={logout} />,
          },
          { path: "/user", element: <User /> },
          { path: "/student", element: <Student /> },
          { path: "/detail/:id", element: <DetailStudent /> },
          { path: "/course", element: <Courses /> },
          {
            path: "/studentregistration",
            element: <StudentRegistration />,
          },
          {
            path: "/studentRecord",
            element: <StudentRecord />,
          },
          { path: "/enterrecord/:id", element: <EnterRecord /> },
          { path: "/dislpayRecord/:id", element: <DisplayRecord /> },
          { path: "/department", element: <Department /> },
          { path: "/useradd", element: <UserAdd /> },
          { path: "/criteria", element: <Ulaagaa /> },
          { path: "/manageRecord", element: <ManageRecord /> },
          {
            path: "/",
            element: <Login />,
          },
          {
            path: "/changepassword",
            element: <ChangePassword />,
          },
        ])}
      />
    </div>
  );
}
export default App;
