import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
import logo from "./../../Images/312171893_500382585442367_742616998542970294_n.jpg";
const DetailStudent = () => {
  const { id } = useParams();
  const [student, setStudent] = useState(null);
   const [userData, setUserData] = useContext(UserContex);
   // console.log(userData?.user);
   const navigate = useNavigate();
   useEffect(() => {
     if (!userData.user) {
       navigate("/");
     }
   }, [userData.user, navigate]);
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/student/getStudent/" + id)
      .then((res) => {
        setStudent(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(student);
  console.log(student && student.student_pc);
  return (
    <div>
      <div className="flex justify-center items-center px-4  bg-black shadow dark:bg-gray-800 h-24 w-full">
        <div className="flex items-center">
          <img
            src={logo}
            alt=""
            className="h-20 w-20 border rounded-full mr-4"
          />
          <h1 className="uppercase text-2xl  font-bold text-white font-serif italic">
            athlete kenenisa Bekele polythecnic college
          </h1>
        </div>
      </div>

      <div className=" flex justify-center item-center ">
        {student ? (
          <div class="my-2 w-1/2 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col">
            <div>
              <div className="my-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex justify-start uppercase">
                <h2>Trainee</h2>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Name:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.first_name +
                      " " +
                      student.middle_name +
                      " " +
                      student.last_name}
                  </p>
                </div>

                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Birth place:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.birth_place}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Sex:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.sex}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Age:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.age}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    12th completed:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.t12th_completed}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    National exam:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.national_exam}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    9th transcript:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.n9th}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    10th transcript:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.t10th}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    11th transcript:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.e11th_t}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    12th transcript:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.t12th_t}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Average of transcrpt
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {(student.n9th +
                      student.t10th +
                      student.e11th_t +
                      student.t12th_t) /
                      4}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Occupation:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.dep_name}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Level:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.level}
                  </p>
                </div>

                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Registrar name:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.r_user}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Enrollment date:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.enrollment_date}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    student photo:
                  </h5>

                  <img
                    src={'https://backend.akptc.et' + student.student_pc}
                    alt=""
                    class="mb-2 pl-2  h-48 w-40 object-cover"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="my-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex justify-start uppercase">
                <h2>Referee</h2>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Referee Name:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.referee}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Referee Zone:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.rf_zone}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Referee Woreda:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.rf_wereda}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Referee Kebele:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.rf_kebele}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Referee Phone:
                  </h5>

                  <p class="mb-2 pl-2 font-normal text-gray-700 dark:text-gray-400">
                    {student.rf_phone}
                  </p>
                </div>
                <div className="flex mb-2">
                  <h5 class="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Referee photo:
                  </h5>

                  <img
                    src={"https://backend.akptc.et" + student.referee_pc}
                    class="mb-2 pl-2  h-48 w-40 object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DetailStudent;
