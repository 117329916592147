import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
import Aheader from "../Aheader/Aheader";
import { Sidebar } from "./Sidebar";
const Department = () => {
  const [department, setDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  console.log(departments);
   const [userData, setUserData] = useContext(UserContex);
   // console.log(userData?.user);
 
   useEffect(() => {
     if (!userData.user) {
       navigate("/");
     }
   }, [userData.user, navigate]);
  const handlesubmit = (e) => {
    e.preventDefault();

    axios
      .post("https://backend.akptc.et/api/dep/enrolld", { department })
      .then((res) => {
        setResponse(res.data.msg);
        alert(res.data.msg);
        // navigate("/admin");

        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.msg);
        console.log(err);
        // setError("please try again");
      });
  };
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/dep/getDep")
      .then((res) => {
        setDepartments(res.data.data);
        console.log(departments);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className=" ">
      <Aheader />
      <div className="flex">
        <Sidebar />
        <div className="bg-gray-400 h-screen ml-60 mt-20 pt-24 w-full ">
          <div className="text-gray-800 flex justify-center mt-2 mb-4">
            <h1 className=" uppercase font-bold text-2xl underline">
              Occupation list
            </h1>
          </div>
          <div className="grid grid-cols-2">
            <div className=" ">
              <div className="px-2 ">
                <table className="border-collapse border border-slate-500 w-full bg-white ">
                  <thead className="font-bold bg-gray-300">
                    <tr>
                      <td className="border border-slate-700 font-serif">#</td>
                      <td className="border border-slate-700 font-serif">
                        Occupation name
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {departments.map((data, index) => (
                      <tr key={index}>
                        <td className="border border-slate-600">{index}</td>
                        <td className="border border-slate-600">
                          {data.dep_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-white  border border-black-1 border-rounded rounded-2xl p-4 h-40 ml-4">
              <form onSubmit={handlesubmit}>
                <label className="mt-4 font-bold">Occupation</label>
                <input
                  placeholder="occupation"
                  type="text"
                  className="w-full  pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                  onChange={(e) => setDepartment(e.target.value)}
                />
                <div className="flex justify-center mt-2">
                  <button
                    type=""
                    className="uppercase bg-red-500 mt-4 text-white px-4 py-1 rounded rounded-fully"
                  >
                    Submit
                  </button>
                </div>
              </form>
              {/* <div>{response && <p>{response}</p>}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Department;
