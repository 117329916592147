import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
function UserAdd() {
  const [userData, setUserData] = useContext(UserContex);
  const [userName, setUserName] = useState(userData.user?.name);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [employeeID, setID] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
   // console.log(userData?.user);
   useEffect(() => {
     if (!userData.user) {
       navigate("/");
     }
   }, [userData.user, navigate]);
  const handlesubmit = (e) => {
    e.preventDefault();

    axios
      .post("https://backend.akptc.et/api/user/uregister", {
        employeeID,
        name,
        role,
        userName,
        password,
      })
      .then((res) => {
        alert(res.data.msg);
        navigate("/admin");
      })
      .catch((err) => alert(err.response.data.msg));
  };
  return (
    <div className="bg-gray-400 flex justify-center items-center h-screen pt-12">
      <div className="w-1/2 h-3/4 bg-white border border-black-1 border-rounded rounded-fully p-4 ">
        <div className="flex justify-center mt-2 mb-4">
          <h1 className="uppercase font-bold text-2xl">users</h1>
        </div>
        <form onSubmit={handlesubmit}>
          <label className="font-bold">Role</label>
          <input
            list="userRole"
            type="text"
            placeholder="Role"
            className="w-full h-8 pl-2 border border-gray-300"
            onChange={(e) => setRole(e.target.value)}
          />
          <datalist id="userRole">
            <option value="Admin" />
            <option value="Registration" />
          </datalist>
          <label className="font-bold">Full Name</label>
          <input
            type="text"
            className="w-full h-8 pl-2 border border-gray-300"
            onChange={(e) => setName(e.target.value)}
          />
          <label className="mt-4 font-bold">Employee ID</label>
          <input
            type="text"
            className="w-full  pl-2 h-8 border border-gray-300 "
            onChange={(e) => setID(e.target.value)}
          />
          <label className="mt-4 font-bold">Password</label>
          <input
            type="text"
            className="w-full h-8 pl-2 border border-gray-300"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="flex justify-center mt-4">
            <button
              type=""
              className="uppercase bg-red-500 text-white px-4 py-1 rounded rounded-fully"
            >
              add user
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserAdd;
