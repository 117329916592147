import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContex } from "../../context API/UserContext";
import Aheader from "../Aheader/Aheader";
import { Sidebar } from "./Sidebar";

const Ulaagaa = () => {
  const [sexc, setSexc] = useState("");
  const [levelc, setLevelc] = useState("");
  const [natscorec, setNatscorec] = useState("");
  const [yearc, setYearc] = useState("");
  const [criteria, setCriteria] = useState([]);
  const navigate = useNavigate();
   const [userData, setUserData] = useContext(UserContex);
   useEffect(() => {
     if (!userData.user) {
       navigate("/");
     }
   }, [userData.user, navigate]);
  const handlesubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.akptc.et/api/criteria/rCriteria", {
        levelc,
        sexc,
        yearc,
        natscorec,
      })
      .then((res) => {
        console.log("res.data.data");
        alert(res.data.msg);
        // navigate("/admin");
        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.msg);
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get("https://backend.akptc.et/api/criteria/getCriteria")
      .then((res) => {
        console.log(res.data.data);
        setCriteria(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className=" ">
      <Aheader />
      <div className="flex">
        <Sidebar />
        <div className="bg-gray-400 h-screen pt-12 ml-60 mt-20 w-full">
          <div className="text-gray-800 flex justify-center mt-2 mb-4">
            <h1 className="uppercase font-bold text-2xl underline">
              List Of Criteria
            </h1>
          </div>
          <div className="grid grid-cols-2 ">
            <div>
              <div className="px-2 ">
                <table className="border-collapse border border-slate-500 w-full bg-white ">
                  <thead className="font-bold bg-gray-300">
                    <tr>
                      <td className="border border-slate-700 font-serif">#</td>
                      <td className="border border-slate-700 font-serif">
                        Sex
                      </td>
                      <td className="border border-slate-700 font-serif">
                        Level
                      </td>
                      <td className="border border-slate-700 font-serif">
                        Year
                      </td>
                      <td className="border border-slate-700 font-serif">
                        National score
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {criteria.map((data, index) => (
                      <tr>
                        <td className="border border-slate-600">{index}</td>
                        <td className="border border-slate-600">{data.sex}</td>
                        <td className="border border-slate-600">
                          {data.level}
                        </td>
                        <td className="border border-slate-600">{data.year}</td>
                        <td className="border border-slate-600">
                          {data.national_score}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-white  border border-black-1 border-rounded rounded-2xl p-4 h-64 ml-4 mr-2">
              <form className="" onSubmit={handlesubmit}>
                <div className="grid grid-cols-2">
                  <div className="mr-4 mb-2">
                    <label className="mt-4 font-bold">Level</label>
                    <input
                      list="levelList"
                      placeholder="Level"
                      type="text"
                      onChange={(e) => setLevelc(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                    <datalist id="levelList">
                      <option value="I" />
                      <option value="II" />
                      <option value="III" />
                      <option value="IV" />
                      <option value="V" />
                    </datalist>
                  </div>
                  <div className=" mb-2">
                    <label className="font-bold">sex</label>

                    <input
                      type="text"
                      placeholder="sex"
                      list="sexList"
                      onChange={(e) => setSexc(e.target.value)}
                      className="w-full  pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                    <datalist id="sexList">
                      <option value="male" />
                      <option value="female" />
                      <option value="male disable" />
                      <option value="female disable" />
                      <option value="male pastoral" />
                      <option value="female pastoral" />
                    </datalist>
                  </div>
                  <div className=" mr-4 mb-4">
                    <label className="font-bold">Year</label>
                    <input
                      type="date"
                      onChange={(e) => setYearc(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                  </div>
                  <div className=" mr-4 mb-4">
                    <label className="font-bold">National score</label>
                    <input
                      placeholder="national score"
                      type="number"
                      onChange={(e) => setNatscorec(e.target.value)}
                      className="w-full mr-4 pl-2 h-12 md:h-8 mb-2 border border-gray-300 rounded-2xl"
                    />
                  </div>
                </div>

                <div className="flex justify-center mt-2">
                  <button
                    type=""
                    className="uppercase bg-red-500 mt-4 text-white px-4 py-1 rounded rounded-fully"
                  >
                    Submit
                  </button>
                </div>
              </form>
              {/* <div>{response && <p>{response}</p>}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ulaagaa;
